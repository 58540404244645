import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { VendorsTypes } from "@/core/data/vendorsType";

export interface Setup {
  currentVendorType: string;
}

@Module
export default class SetupSuitModule extends VuexModule implements Setup {
  currentVendorType = VendorsTypes.Supplier;

  get getCurrentVendorType() {
    return this.currentVendorType;
  }
  @Mutation
  [Mutations.SET_VENDOR_MUTATION](vendorType: VendorsTypes) {
    this.currentVendorType = vendorType;
  }

  @Action
  [Actions.SET_VENDOR_ACTION](vendorType: string) {
    this.context.commit(Mutations.SET_VENDOR_MUTATION, vendorType);
  }
}
