import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/test",
        name: "test",
        component: () => import("@/views/testView.vue"),
        meta: {
          pageTitle: "test",
          breadcrumbs: ["test"],
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboard"],
        },
      },

      {
        path: "/pages/setups",
        name: "setups",
        component: () => import("@/views/custom/SetupSuite/SetupSuit.vue"),
        meta: {
          pageTitle: "Enterprise Setup Suite",
          breadcrumbs: ["Setup"],
        },
      },
      {
        path: "/banking",
        name: "banking",
        component: () => import("@/views/custom/BankingView.vue"),
        meta: {
          pageTitle: "Banking",
          breadcrumbs: ["Banking"],
        },
      },

      {
        path: "/banking/cashinwallet",
        name: "cashinwallet",
        component: () => import("@/views/custom/banking/WalletWiew.vue"),
        meta: {
          pageTitle: "Banking",
          breadcrumbs: ["Banking"],
        },
      },
      {
        path: "/banking/:banker/detail/:id",
        name: "bankerdetail",
        props: true,
        component: () => import("@/views/custom/banking/bankerDetailView.vue"),
        meta: {
          pageTitle: "Banking",
          breadcrumbs: ["Banking"],
        },
      },
      {
        path: "/pages/profile",
        name: "account",
        component: () => import("@/views/crafted/account/AccountView.vue"),
        meta: {
          pageTitle: "Profile",
          middleware: "auth",
          breadcrumbs: ["Profile"],
        },
        children: [
          {
            path: "/pages/profile/initial",
            name: "initial-pofile",
            component: () => import("@/views/crafted/account/InitalView.vue"),
            meta: {
              pageTitle: "Initial",
            },
          },
          {
            path: "/pages/profile/ownership",
            name: "ownership-profile",
            component: () =>
              import("@/views/crafted/account/OwnershipView.vue"),
            meta: {
              pageTitle: "Ownership",
            },
          },
          {
            path: "/pages/profile/management",
            name: "management-profile",
            component: () =>
              import("@/views/crafted/account/ManagementView.vue"),
            meta: {
              pageTitle: "Management",
            },
          },
        ],
      },
      {
        path: "/pages/profile/settings",
        name: "settings",
        component: () => import("@/views/crafted/account/Settings.vue"),
        meta: {
          pageTitle: "Settings",
        },
      },
      {
        path: "/pages/profile/overview",
        name: "overview",
        component: () => import("@/views/crafted/account/Overview.vue"),
        meta: {
          pageTitle: "Overview",
        },
      },
      {
        path: "/business-wallet/expenses",
        name: "expenses",
        component: () => import("@/views/custom/expenses/ExpensesView.vue"),
        meta: {
          breadcrumbs: ["Expenses"],
        },
        children: [
          {
            path: "/business-wallet/expenses/operating",
            name: "operating",
            component: () =>
              import("@/views/custom/expenses/OperatingExpenses.vue"),
            meta: {
              pageTitle: "Operating Expenses",
            },
          },
          {
            path: "/business-wallet/expenses/goods",
            name: "goods",
            component: () => import("@/views/custom/expenses/GoodsSold.vue"),
            meta: {
              pageTitle: "Goods Sold",
            },
          },
        ],
      },
      {
        path: "/business-wallet/vendor",
        name: "vendor",
        component: () => import("@/views/custom/VendorView.vue"),
        meta: {
          pageTitle: "Vendor",
          breadcrumbs: ["Vendor"],
        },
      },
      {
        path: "/business-wallet/vendor/view/:number",
        name: "vendorviewdetail",
        props: true,
        component: () => import("@/views/custom/VendorViewDetail.vue"),
        meta: {
          pageTitle: "Vendor View",
          breadcrumbs: ["VendorView"],
        },
      },
      {
        path: "/business-wallet/payroll",
        name: "payroll",
        component: () => import("@/views/custom/PayrollView.vue"),
        meta: {
          pageTitle: "Payroll",
          breadcrumbs: ["Payroll"],
        },
      },
      {
        path: "/business-wallet/customer",
        name: "customer",
        component: () => import("@/views/custom/sampleView.vue"),
        meta: {
          pageTitle: "Customer",
          breadcrumbs: ["Customer"],
        },
      },
      {
        path: "/business-wallet/finance",
        name: "finance",
        component: () => import("@/views/custom/FinanceView.vue"),
        meta: {
          pageTitle: "Financing",
          breadcrumbs: ["Financing"],
        },
      },
      {
        path: "/business-wallet/cash-sales",
        name: "cash-sales",
        component: () =>
          import("@/views/custom/customer/Cash-Sales/CashSales.vue"),
        meta: {
          pageTitle: "Cash Sales",
          breadcrumbs: ["Cash Sales"],
        },
      },

      {
        path: "/business-wallet/cash-sales/order-profile",
        name: "cash-order-profile",
        component: () =>
          import("@/views/custom/customer/Cash-Sales/OrderProfile.vue"),
        meta: {
          pageTitle: "Cash Sales",
          breadcrumbs: ["Cash Sales"],
        },
      },

      {
        path: "/business-wallet/cash-sales/new-order",
        name: "new-order-profile",
        component: () =>
          import("@/views/custom/customer/Cash-Sales/NewOrder.vue"),
        meta: {
          pageTitle: "Cash Sales",
          breadcrumbs: ["Cash Sales"],
        },
      },
      {
        path: "/business-wallet/cash-sales/confirm-order",
        name: "cash-confirm-order",
        component: () =>
          import("@/views/custom/customer/Cash-Sales/ConfirmOrder.vue"),
        meta: {
          pageTitle: "Cash Sales",
          breadcrumbs: ["Cash Sales"],
        },
      },
      {
        path: "/business-wallet/cash-sales/view-order/:id",
        name: "cash-view-order",
        props: true,
        component: () =>
          import("@/views/custom/customer/Cash-Sales/ViewOrder.vue"),
        meta: {
          pageTitle: "Cash Sales -View order",
          breadcrumbs: ["Cash Sales"],
        },
      },
      {
        path: "/business-wallet/settings",
        name: "business-wallet-settings",
        component: () =>
          import("@/views/custom/customer/businessWalletSettingsView.vue"),
        meta: {
          pageTitle: "Settings",
          breadcrumbs: ["Business Wallet Settings"],
        },
      },
      {
        path: "/business-wallet/credit-sales",
        name: "credit-sales",
        component: () =>
          import("@/views/custom/customer/Credit-Sales/CreditSales.vue"),
        meta: {
          pageTitle: "Credit Sales",
          breadcrumbs: ["Credit Sales"],
        },
      },
      {
        path: "/:id/business-wallet/credit-sales/order-profile",
        name: "credit-order-profile",
        component: () =>
          import("@/views/custom/customer/Credit-Sales/OrderProfile.vue"),
        meta: {
          pageTitle: "Credit Sales",
          breadcrumbs: ["Credit Sales"],
        },
      },
      {
        path: "/business-wallet/credit-sales/confirm-order",
        name: "credit-confirm-order",
        component: () =>
          import("@/views/custom/customer/Credit-Sales/ConfirmOrder.vue"),
        meta: {
          pageTitle: "Credit Sales",
          breadcrumbs: ["Credit Sales"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - Smart Books`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
